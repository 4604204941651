/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */


ion-icon {
  font-size: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

ion-grid {
  border: solid 1px #ddd;
}

.whatisnew {
  font-size: 125%;
  color:green;
  font-style: oblique;
  font-weight: bold;
}

.moto {
  font-size: 100%;
  color:green;
  font-style: oblique;
}

.addtocart:hover{
  border-radius: 2em;
  background-color: #f8e972 !important;
  font-size: 100%;
}

.plusminus:hover{
  font-size: 90%;
}

ion-item {
  --highlight-color-focused: none;
}